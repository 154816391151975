<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <input v-model="typeform.name" type="text" class="form-control" name="name" :class="{
              'is-invalid': typesubmit && $v.typeform.name.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.name.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>URL de la API</label>
            <input v-model="typeform.url" type="text" class="form-control" name="short" :class="{
              'is-invalid': typesubmit && $v.typeform.url.$error,
            }" />
            <div v-if="typesubmit && $v.typeform.url.$error" class="invalid-feedback">
              <span v-if="!$v.typeform.url.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mb-0">
        <div>
          <button type="submit" class="btn btn-primary">Guardar</button>
          <button @click="$emit('closeModa')" type="button" class="btn btn-secondary ms-1">
            Cancelar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      url: {
        required,
      },
    },
  },
  methods: {
    save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("error");
      } else {
        if (this.typeform._id) {
          this.$http
            .put(`exchange-api/${this.typeform._id}`, this.typeform)
            .then((response) => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Api guardada con exito",
                showConfirmButton: false,
                timer: 1500,
              });
              console.log(response);
              this.$emit("closeModa");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.$http
            .post("exchange-api", this.typeform)
            .then((response) => {
              console.log(response, "Api post exchange");
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Api registrada con exito",
                showConfirmButton: false,
                timer: 1500,
              });

              this.$emit("closeModa");
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    closeModal() {
      this.$emit("handleChecked");
    },
  },
};
</script>